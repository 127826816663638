import React, { Component } from 'react';
import {Grid,Typography,TextField,Button,Paper,Modal,LinearProgress,Checkbox } from '@material-ui/core';

import moment from 'moment';
import {apiurl}from '../constant';
import ReCAPTCHA  from  'react-google-recaptcha'
export default class NewRegister extends Component {  
    constructor(props){
    super(props);
    this.state={
        open:false,
        isHuman:true,
        completed: 0,
        buffer: 10,
        coursedtl:{}
    }
}

componentDidMount() {
  fetch(apiurl+'hpb.gettraindtl/'+this.props.match.params.id)
  .then(res=>res.json()).then(res=>this.setState({coursedtl:res}))

  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed } = this.state;
    if (completed > 100) {
      this.setState({ completed: 0, buffer: 10 });
    } else {
      const diff = Math.random() * 10;
      const diff2 = Math.random() * 10;
      this.setState({ completed: completed + diff, buffer: completed + diff + diff2 });
    }
  };

  render() {
    const { completed, buffer,coursedtl } = this.state;
    const {course,mode,state,date,discntprice,isHRDC}=coursedtl;

    return (
        <React.Fragment>
            <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
        >
        <div style={{display:'flex',  justifyContent:'center', color:"#fff", height:'100vh',alignItems:'center'}}>
        <Grid xs={12} sm={6} style={{ textAlign:'center'}}>
            <Typography color="inherit" variant="h4" id="modal-title">
              Sending Your Registration
            </Typography>
            
      
            <LinearProgress color="secondary" variant="buffer" value={completed} valueBuffer={buffer} />
            </Grid>
            </div>
            
        </Modal>

            <Grid xs={12} sm={10} md={8} lg={6} style={{margin:'0 auto',}}>
            <Paper style={{padding:'5vmin', marginTop:'2vmin'}}>
        <Typography variant="headline">
        {course}
        </Typography>
        <Typography variant="subheading" gutterBottom>
        {`${state} : ${moment(date, "YYYY-MM-DD").format('MMMM YYYY')} - ${mode}`}
        </Typography>
        <Typography variant="subheading" gutterBottom>
        {`Price : RM ${discntprice}`}
        </Typography>
        <Typography variant="h6" gutterBottom>
        PARTICIPANTS DETAILS
        </Typography>
        <form onSubmit={this.handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="fullName"
              name="trneeName"
              label="Full Name"
              fullWidth
              autoComplete="fname"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="trneeEmail"
              label="Email Address"
              fullWidth
              type="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              id="icno"
              name="trneeIcNo"
              label="Identity Card #"
              fullWidth
              value={this.props.match.params.ic}
              autoComplete="icno"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              required
              id="tel"
              name="trneePhNo"
              label="Handphone #"
              type="tel"
              fullWidth
              autoComplete="tel"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="cmpny"
              name="trneeCmpny"
              label="Company"
              fullWidth
              autoComplete="cmpny"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="post"
              name="trneePost"
              label="Position"
              fullWidth
              autoComplete="post"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="caddrs"
              name="trneeAddr"
              label="Company Address"
              multiline
              rows="3"
              fullWidth
              autoComplete="caddrs"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="diet"
              name="trneeDiet"
              label="Special Dietary Needs/ Allergies"
              fullWidth
              autoComplete="diet"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="referrer"
              name="referrer"
              label="Referrer"
              fullWidth
              autoComplete="referrer"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="discntcode"
              name="discntcode"
              label="Promo Code"
              fullWidth
              autoComplete="discntcode"
            />
          </Grid>
          {isHRDC==1 && <Grid item xs={12} sm={6}><label for="needhrdf">Claim with HRDC?</label>
             <Checkbox 
             id="needhrdf"
              name="needhrdf"
        color="primary"
              fullWidth
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
          </Grid>}

          <ReCAPTCHA style={{margin:'0 auto'}} 
    ref="recaptcha"
    sitekey="6LcPx30UAAAAAOsBUhDlLRLF8MzwyHiUN6K9saj0"
    onChange={()=>{this.setState({isHuman:true})}}
  />
          {this.state.isHuman && <Button
                variant="contained"
                      color="primary"
                      type="submit" value="Submit"
                      className=""
                      fullWidth>Register </Button>}
        </Grid>
        
         </form>
         </Paper>
         </Grid>
  </React.Fragment>
    );
  }

  handleSubmit=(event)=>{
  //  alert('An essay was submitted: ' + this.state.value);
  
    event.preventDefault();
    
    this.setState({open:true});
    
    this.timer = setInterval(this.progress, 500);

 const data = new FormData(event.target);
 let encodedata= new URLSearchParams();
 for (let name of data.keys()) {
  encodedata.append(name, data.get(name));
}

encodedata.append('trainFK', this.props.match.params.id);
fetch(apiurl+'hpb.register', {
  method: 'POST',
  mode: "no-cors",
  headers: {
     "Content-Type": "application/json; charset=utf-8",
},
  body: encodedata,
}).then(res=>{console.log(res);
    
    this.setState({open:false});
this.props.history.replace("/tq")}).catch(e=>{alert(e)

    this.setState({open:false});})

  } 
} 


