import React, { Component } from 'react';
import moment from 'moment';

import { Typography, Grid, Card, CardContent, CardActions, CardActionArea, CardMedia, Button, Modal } from '@material-ui/core';

import { apiurl } from '../constant';

import NoCourseOffer from './NoCourseOffer';



class TrainingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: [],
      courseName: "",
      link: "",
      open: true,
    }
  }
  componentDidMount() {
    const { code } = this.props.match.params;
    fetch(apiurl + 'hpb.getcourseName/' + code)
      .then(res => res.json()).then(res => this.setState({ courseName: res.courseName, link: res.courseLink }))

    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;

    // Typical usage (don't forget to compare props):
    if (locationChanged) {
      this.fetchData();
    }
  }

  render() {
    const { courseList, link } = this.state;
    let cnt = 0;
    return (
      <React.Fragment>
        <Grid item xs={12} className="trBox">
          {courseList.length > 0 &&
            <Grid container direction="row" justify="center" spacing={10}>
              {courseList.map((item, index) => {

                cnt > 1 ? cnt = 0 : cnt++;

                let month = moment(item.trainDtStart, "YYYY-MM-DD").format('MMMM')
                return (

                  <Card className="trcard" onClick={() => { this.props.history.push(`/${link}/${item.trainPK}`) }}>
                    <CardActionArea  className="tractionarea">
                      <CardMedia  className="trmedia"
                        style={{
                          backgroundColor: item.isFulltime ? "#0ecea1" : "#ce940d",
                        }}
                      >
                        <Typography color='inherit' align='center' gutterBottom variant="h5" component="p">
                          {item.stateName}<br />
                          {item.isFulltime ? "Full time" : "Part time"}
                        </Typography>
                      </CardMedia>

                      <CardContent className="trcontent">
                      <div>
                        <Typography align='center' gutterBottom variant="h4" component="h2">
                          {month}
                        </Typography>
                        <Typography component="p">
                          <p dangerouslySetInnerHTML={{ __html: item.trainDetail }} ></p>
                        </Typography>
                        </div>
                        <div>
                        {item.discntVal &&
                          <React.Fragment>
                            <Typography align='center' gutterBottom component="p">
                              Normal Fee : RM {item.courseFee}
                            </Typography>
                            <Typography align='center' gutterBottom variant="h6" >
                              NOW : RM {item.courseFee - (item.courseFee * item.discntVal / 100).toFixed(0)}
                            </Typography>
                            <Typography align='center' gutterBottom component="p">
                              * valid until : {moment(item.discntTo, "YYYY-MM-DD").format('DD MMMM YYYY')}
                            </Typography>
                            <Typography align='center' gutterBottom variant="h6" >
                            Non-Malaysian: USD $ {item.courseFeeInt}
                          </Typography>
                          </React.Fragment>
                        }
                        {!item.discntVal &&
                        <div>
                          <Typography align='center' gutterBottom variant="h6" >
                            Fee 
                          </Typography>
                          
                          <Typography align='center' gutterBottom variant="h6" >
                            Malaysian    : RM {item.courseFee}
                          </Typography>
                          
                          <Typography align='center' gutterBottom variant="h6" >
                            Non-Malaysian: USD $ {item.courseFeeInt}
                          </Typography>
                          </div>
                        }
                        </div>
                      </CardContent>

                      <Button size="large" fullWidth="true" variant="contained" color="secondary">
                        Register Now
                              </Button>
                    
                    </CardActionArea>
                    <CardActions>
                      {/* <Button size="small" color="primary">
                                Share
                              </Button> */}
                      <Button size="small" color="primary" onClick={() => window.open("http://training.holisticslab.my", "_blank")}>
                        Learn More
                        </Button>
                    </CardActions>
                  </Card>
                )
              })}
            </Grid>
          }
          {(courseList.length === 0 && !this.state.open) &&
            <NoCourseOffer />
          }
        </Grid>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
        >
          <div style={{ display: 'flex', justifyContent: 'center', color: "#fff", height: '100vh', alignItems: 'center' }}>
            <Grid xs={12} sm={6} style={{ textAlign: 'center' }}>
              <Typography color="inherit" variant="h4" id="modal-title">
                Loading
            </Typography>
            </Grid>
          </div>

        </Modal>
      </React.Fragment>
    );
  }

  fetchData = () => {
    this.setState({ open: true })
    const { code, year, month, mod, loc } = this.props.match.params;

    let url = ""
    if (month) {
      url = `${apiurl}hpb.getraining/${code}/${year}/${month}`
    }
    else if (year) {
      url = `${apiurl}hpb.getraining/${code}/${year}`
    }
    else {
      url = `${apiurl}hpb.getraining/${code}`
    }

    fetch(url)
      .then(res => res.json()).then(res => {
        let data = res;
        if (typeof mod !== "undefined") {
          data = res.filter(({ isFulltime }) => isFulltime == mod)

        }
        else if (typeof loc !== "undefined") {
          data = res.filter(({ stateName }) => stateName.toUpperCase() == loc.toUpperCase())

        }
        this.setState({ courseList: data, open: false });

        //.filter(({website}) => website === 'yahoo')
      })
  }
}

export default TrainingList;
