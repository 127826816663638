import React, { Component } from 'react';

import {AppBar,Toolbar,Typography,IconButton,Icon,Hidden } from '@material-ui/core';
import '../asset/css/trainlist.css';

import logo from '../asset/images/whiteLogo.png';

import PageNotFound from './PageNotFound';

import {apiurl}from '../constant';
import {
  Route,Switch
} from "react-router-dom";


import TrainingList from "../screens/TrainingList";
import Register from "../screens/Register";
import NewRegister from "../screens/NewRegister";
import RepeatRegister from "../screens/RepeatRegister";
import NewRegisterInt from "../screens/NewRegisterInt";
import RepeatRegisterInt from "../screens/RepeatRegisterInt";
import ThankYou from "../screens/ThankYou";



class Training extends Component {
    constructor(props){
        super(props);
        this.state={
            courseList:[],
            courseName:""
        }
    }
    componentDidMount(){
        this.getTrainingDetails();
    }

    componentDidUpdate(prevProps, prevState) {
      if(prevProps.match.params.code!==this.props.match.params.code){
        //Perform some operation here
        this.getTrainingDetails();
      }
    }
    getTrainingDetails=()=>{
      fetch(apiurl+'hpb.getcourseName/'+this.props.match.params.code)
      .then(res=>res.json()).then(res=>this.setState({courseName:res.courseName}))
    }
  render() {
      const {courseName}=this.state;
    return (
        <React.Fragment>
            
            <AppBar position="sticky" style={{ background: 'green'}}>
                <Toolbar>
                <Hidden xsDown>
                    <img src={logo}  alt="logo"  style={{ height:'10vh', padding:'2vh'}} />
                    </Hidden>
                    <Typography variant="h6" color="inherit" style={{flexGrow: 1}} >
            {courseName}
          </Typography>
          <div style={{marginLeft: 'auto'}}>
          <IconButton  color="inherit"  onClick={()=>{this.props.history.replace("/")}}>
              
        <Icon > dashboard</Icon>
            </IconButton>
            </div>
                </Toolbar>
            </AppBar>
            <Switch>
            <Route exact path={"/tq"} component={ThankYou}/>
           <Route  exact path={this.props.match.path} component={TrainingList}/>
           <Route  exact path={`${this.props.match.path}/filter/:year/:month`} component={TrainingList}/>
           <Route  exact path={`${this.props.match.path}/filter/:year`} component={TrainingList}/>
           <Route  exact path={`${this.props.match.path}/mod/:mod`} component={TrainingList}/>
           <Route  exact path={`${this.props.match.path}/loc/:loc`} component={TrainingList}/>
           <Route exact path={`${this.props.match.path}/:id/:ic/new`} component={NewRegister}/>
           <Route exact path={`${this.props.match.path}/:id/:ic`} component={RepeatRegister}/>
           <Route exact path={`${this.props.match.path}/:id/:ic/intnew`} component={NewRegisterInt}/>
           <Route exact path={`${this.props.match.path}/:id/:ic/int`} component={RepeatRegisterInt}/>
           <Route exact path={`${this.props.match.path}/:id`} component={Register}/>
           <Route component={PageNotFound}/>
            </Switch>
            
    </React.Fragment>
    );
  }
}

export default Training;
