import React, { Component } from 'react';
import {Grid,Typography,Paper} from '@material-ui/core';

export default class NoCourseOffer extends Component {

    componentDidMount(){
    }
  render() {
    return (
        <React.Fragment>
            <Grid xs={12} sm={6} style={{margin:'0 auto',}}>
            <Paper  style={{padding:'10vmin', marginTop:'5vmin'}}>
            <Typography variant="h2" gutterBottom>
        We Are Sorry
        </Typography>
        <Typography variant="h5" gutterBottom>
       Currently we are not offering this training session
        </Typography>
        <Typography variant="h6" gutterBottom>
        For enquiry please contact us at <a href="mailto:admin@holisticslab.my"> admin@holisticslab.my</a>
        </Typography>
        </Paper>
         </Grid>
  </React.Fragment>
    );
  }

} 


