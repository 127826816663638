import React, { Component } from 'react';
import {
  Route,
  HashRouter
} from "react-router-dom";

import './asset/css/App.css'; 
import LandingPage from "./screens/LandingPage";
import Training from "./screens/Training";

class App extends Component {
  render() {
    return (
      <HashRouter>
        <div className="App">
      <Route exact path="/" component={LandingPage}/>
      <Route path="/:code" component={Training} />
       
       </div>
      </HashRouter>
    );
  }
}

export default App;
