import React, { Component } from 'react';

import {AppBar,Toolbar,Typography,Grid,Button,Hidden,Card, CardContent, CardActions, CardActionArea, CardMedia  } from '@material-ui/core';
import '../asset/css/style.css';

import moment from 'moment';
import bg1 from '../asset/images/bg1.JPG';
import logo from '../asset/images/logo.png';
import logooth from '../asset/images/header01.png';
import {apiurl}from '../constant';




class LandingPage extends Component {
    constructor(props){
        super(props);
        this.state={
            courseList:[]
        }
    }
    componentDidMount(){
        fetch(apiurl+'hpb.getcourse')
        .then(res=>res.json()).then(res=>{
            this.setState({courseList:res}
                )})
    }
  render() {
      const {courseList}=this.state;
      const divwidth=100/courseList.length;
      
    return (
              <div id="landingPage" style={{ backgroundImage: `url(${bg1})`,backgroundSize:'cover',backgroundPosition:'center', backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed'}}> 
      
            <AppBar  position="sticky">
                <Toolbar className="landingToolbar">
                    <img src={logo}  alt="logo"  style={{ height:'10vh', padding:'2vh'}} />
                    <Hidden xsDown>
                    <img src={logooth}  alt="logo"  style={{ height:'10vh', padding:'2vh'}} />
                    </Hidden>
                </Toolbar>
            </AppBar>
            
            <Grid item xs={12} className="trBox">
                

          {courseList.length > 0 &&
            <Grid container direction="row" justify="center">
              {courseList.map((item, index) => {

                return (

                  <Card className="landingCard"  onClick={()=>this.props.history.push(`/${item.courseLink}`)}>
                    <CardActionArea  className="tractionarea">
                      <CardMedia  className="trmedia"  style={{
                          backgroundColor:"#464646",
                        }} >
                        <Typography  style={{
                            padding:'2vmin',
                        }} color='inherit' align='center' gutterBottom variant="h5" component="p">
                        {item.courseName}
                        </Typography>
                      </CardMedia>

                      <CardContent className="trcontent">
                      <p dangerouslySetInnerHTML={{ __html:item.courseDesc}} ></p>
                      </CardContent>

                      <Button size="large" fullWidth="true" variant="contained" color="secondary">
                        View Session
                              </Button>
                    
                    </CardActionArea>
                 
                  </Card>
                )
              })}
              
            </Grid>
          }
       
           
            {
                
            //      courseList.map((item,index)=>{
            //          return(
            //  <Grid item sm={12} md={6} className="landingMenu" onClick={()=>this.props.history.push(`/${item.courseLink}`)}>
            //            <Typography variant="h5" gutterBottom color="inherit">
            //             {item.courseName}
            //             </Typography>
            //             <Hidden xsDown>
            //             <p dangerouslySetInnerHTML={{ __html:item.courseDesc}} ></p>
            //             </Hidden>
            //             <Button className="landingButton" variant="outlined" fullWidth color="inherit" >Register Here</Button>
            //          </Grid>
            //          )
            //      }
            //      )
            }
            </Grid>
            </div>
    );
  }
}

export default LandingPage;
