import React, { Component } from 'react';
import {Grid,Typography,Paper} from '@material-ui/core';

export default class ThankYou extends Component {

    componentDidMount(){
    }
  render() {
    return (
        <React.Fragment>
            <Grid xs={12} sm={6} style={{margin:'0 auto',}}>
            <Paper  style={{padding:'10vmin', marginTop:'5vmin'}}>
            <Typography variant="h1" gutterBottom>
        Thank You
        </Typography>
        <Typography variant="h5" gutterBottom>
        for registering with us.
        </Typography>
        <Typography variant="h5" gutterBottom>
        You will recieve an invoice email shortly.
        </Typography>
        </Paper>
         </Grid>
  </React.Fragment>
    );
  }

} 


