import React, { Component } from 'react';
import {Grid,Typography,TextField,Button,Paper,Modal,LinearProgress } from '@material-ui/core';

import moment from 'moment';
import {apiurl}from '../constant';
import ReCAPTCHA  from  'react-google-recaptcha'
export default class Register extends Component {  
    constructor(props){
    super(props);
    this.state={
        open:false,
        isHuman:true,
        completed: 0,
        buffer: 10,
        coursedtl:{},
        message:"",
        ic:'',
        isinternational:false,
    }
}

componentDidMount() {
  fetch(apiurl+'hpb.gettraindtl/'+this.props.match.params.id)
  .then(res=>res.json()).then(res=>this.setState({coursedtl:res}))

  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  progress = () => {
    const { completed } = this.state;
    if (completed > 100) {
      this.setState({ completed: 0, buffer: 10 });
    } else {
      const diff = Math.random() * 10;
      const diff2 = Math.random() * 10;
      this.setState({ completed: completed + diff, buffer: completed + diff + diff2 });
    }
  };

  render() {
    const { completed, buffer,coursedtl,message ,isinternational} = this.state;
    const {course,mode,state,date}=coursedtl;

    return (
        <React.Fragment>
            <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
        >
        <div style={{display:'flex',  justifyContent:'center', color:"#fff", height:'100vh',alignItems:'center'}}>
        <Grid xs={12} sm={6} style={{ textAlign:'center'}}>
            <Typography color="inherit" variant="h4" id="modal-title">
              Checking your Id
            </Typography>
            
      
            <LinearProgress color="secondary" variant="buffer" value={completed} valueBuffer={buffer} />
            </Grid>
            </div>
            
        </Modal>

            <Grid xs={12} sm={10} md={8} lg={6} style={{margin:'0 auto',}}>
            <Paper style={{padding:'5vmin', marginTop:'2vmin'}}>
        <Typography variant="headline">
        {course}
        </Typography>
        <Typography variant="subheading" gutterBottom>
        {`${state} : ${moment(date, "YYYY-MM-DD").format('MMMM YYYY')} - ${mode}`}
        </Typography>
        <Typography variant="h6" gutterBottom>
        PARTICIPANTS DETAILS
        </Typography>
        <form onSubmit={this.handleSubmit}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              required
              id="icno"
              name="trneeIcNo"
              label={isinternational?"Passport No":"Identity Card #"}
              fullWidth
              autoComplete="icno"
              onChange={(event) => {
                const value =  event.target.value;
                this.setState({
                    ic: value,message:''
                });
            }} 
            />
            {message && 
            <Typography variant="h6" gutterBottom color="error">
        {message}
        </Typography>} 
          </Grid>
         <Button
                variant="contained"
                      color="primary"
                      onClick={this.handleSubmit}
                      className=""
                      fullWidth disabled={this.state.ic.length<5}>Next </Button>
                      
         <Button
                variant="contained"
                      color="secondary"
                      onClick={()=>{this.setState({isinternational:isinternational?false:true})}}
                      className=""
                      fullWidth >{isinternational?"Malaysian":"Non-Malaysian"} </Button>
                      
        </Grid>
        
         </form>
         </Paper>
         </Grid>
  </React.Fragment>
    );
  }

  handleSubmit=()=>{
    const{isinternational}=this.state;
    this.setState({open:true,message:''});
    
    this.timer = setInterval(this.progress, 500);


    fetch(apiurl + 'hpb.checkreg/'+this.props.match.params.id+'/' + this.state.ic)
    .then(res => res.json()).then(res => {
      if(res.isexist){

        if(res.isregister){
          this.setState({message:'Sorry you already register to the course. Kindly contact us if you have any problems.'});
        }
        else{
          this.setState({message:''});
        if(isinternational){
          this.props.history.push(`${this.props.match.params.id}/${this.state.ic}/int`);
        }
        else{
          this.props.history.push(`${this.props.match.params.id}/${this.state.ic}`);
        }
        }
        }
      else{
        if(isinternational){
          this.props.history.push(`${this.props.match.params.id}/${this.state.ic}/intnew`);
        }
        else{
          this.props.history.push(`${this.props.match.params.id}/${this.state.ic}/new`);
        }
      }
     this.setState({open:false});
    }).catch(e=>{
      
      this.props.history.push(`${this.props.match.params.id}/${this.state.ic}/new`)
      alert(e)
      this.setState({open:false});})


  } 
} 


